@import "@/assets/scss/var.scss";
.returnList {
    &-info {
        background: #fff;
        padding: 20px;
    }
    &-info2 {
        > div {
            padding: 10px;
            display: flex;
            justify-content: flex-start;
        }
    }
    &-imgs{
        align-items: center;
        .img-item{
            display: inline-block;
            width: 100px;
            height: 100px;
        }
        .img-item+.img-item{
            margin-left: 10px;
        }
    }
    .amt {
        color: $color-primary;
        font-weight: bold;
        font-size: 16px;
    }
}
